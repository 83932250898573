.button {
  background-color: var(--color-offblack);
  border-radius: var(--border-radius);
  border: solid 1px var(--color-offblack);
  color: var(--color-white);
  cursor: pointer;
  font-family: var(--font-family-sans);
  font-size: var(--line-height-s);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  margin: 0;
  padding: 10px 12px;
  transition: border-color 200ms transform var(--transition), color 200ms transform var(--transition);

  & svg {
    height: 20px;
    width: 20px;
  }

  &:hover,
  &:focus {
    border-color: var(--color-white);
  }

  &.active {
    border-color: var(--color-green);
    color: var(--color-green);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  @media (--viewport-m) {
    font-size: var(--font-size-l);
    padding: 14px;

    & svg {
      height: 30px;
      width: 30px;
    }
  }
}

.inner {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.loader {
  height: 18px;
  width: 18px;

  & svg {
    height: 18px;
    width: 18px;
  }
}
