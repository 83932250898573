.tag {
  background: var(--color-offblack);
  border-radius: var(--border-radius);
  color: var(--color-white);
  font-family: var(--font-family-mono);
  font-size: var(--font-size-xs);
  line-height: 1;
  padding: 4px;

  @media (--viewport-m) {
    font-size: var(--font-size-s);
    padding: 6px;
  }

  &.track {
    background-color: var(--color-blue);
    font-weight: var(--font-weight-bold);
  }

  &.album {
    background-color: var(--color-red);
    font-weight: var(--font-weight-bold);
  }
}
