.container {
  position: relative;
}

.fakeThumbnail {
  pointer-events: none;
}

.buttonContainer {
  align-items: center;
  background-color: rgb(0 0 0 / 75%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
