.container {
  align-items: flex-start;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 0 24px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-modal);
}

.background {
  background-color: rgb(0 0 0 / 75%);
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.modal {
  background-color: var(--color-black);
  border-radius: var(--border-radius);
  border: solid 1px var(--color-offblack);
  cursor: default;
  left: 50%;
  margin-top: 10vh;
  max-width: var(--container-m);
  overflow-y: scroll;
  padding: 24px;
  width: 100%;
  z-index: 2;
}
