.container {
  display: block;
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: solid 1px var(--color-offblack);
  }
}

.content {
  align-items: flex-start;
  background-color: var(--color-black);
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  position: relative;

  @media (--viewport-m) {
    transition: transform var(--transition);

    &:hover {
      transform: scale(1.02);
      z-index: 2;
    }
  }
}

.imageContentContainer {
  display: flex;
  margin: 0 20px 0 0;
}

.imageContainer {
  background-color: var(--color-offblack);
  flex-shrink: 0;
  height: 66px;
  margin: 0 20px 0 0;
  position: relative;
  width: 66px;

  & span,
  & span img {
    height: 100% !important;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100% !important;
  }

  &::after {
    content: "";
    background-color: rgb(0 0 0 / 20%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  @media (--viewport-m) {
    height: 80px;
    margin: 0 25px 0 0;
    width: 80px;
  }
}

.playButton {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 48px;
  left: 50%;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  z-index: 2;

  & svg {
    fill: var(--color-white);
    height: 24px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
  }
}

.contentContainer {
  & h3 {
    color: var(--color-white);
    font-family: var(--font-family-mono);
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-s);
    margin: 0 0 4px;

    @media (--viewport-m) {
      font-size: var(--font-size-l);
    }
  }

  & p {
    color: var(--color-offwhite);
    font-family: var(--font-family-mono);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-s);
    margin: 0;

    @media (--viewport-m) {
      font-size: var(--font-size-m);
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: 6px 0 0;

  & > :not(last-child) {
    margin: 0 8px 0 0;
  }

  @media (--viewport-m) {
    margin: 12px 0 0;
  }
}
